import { Box } from '@mui/material'
import LogoIcon from '../assets/icons/logoIcon'

export default function Header() {
  return (
    <Box sx={{width:"100%"}}>
        <LogoIcon/>
    </Box>
  )
}
