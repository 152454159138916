import { Box, Stack, Button, Collapse, CircularProgress } from "@mui/material";
import { ReactNode, useState } from "react";

import { ReasonsProps } from "../../utils/data.def";
import Header from "../../components/Header";
import Step2 from "./components/Step2";
import Step1 from "./components/Step1";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import DeleteComplete from "./components/DeleteComplete";
import { sendVerificationCode, verifyCodeAsync } from "../../api/api";
import { useSnackbar } from "notistack";

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [reasonList, setReasonList] = useState<ReasonsProps[]>([]);
  const [description, setDescriptions] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verficationCode, setverficationCode] = useState<string>("");

  const [result, setResult] = useState(null) as any;

  const onSuccess = (data: any) => {
    setResult(data);
    setStep((el) => el + 1);
    setLoading(false);
  };

  const onError = (message: any) => {
    enqueueSnackbar(message ?? "Erreur de suppression de compte", {
      variant: "error",
      anchorOrigin: {
        horizontal: "center",
        vertical: "top",
      },
    });
    setLoading(false);
  };

  // handle form step
  const stepHandler = async () => {
    if (step === 3) {
      setLoading(true);
      return await sendVerificationCode(`+${phoneNumber}`, onSuccess, onError);
    }
    if (step === 4) {
      setLoading(true);
      const reasons = [...reasonList.map((reason) => reason.value)];
      return await verifyCodeAsync({
        phoneNumber: `+${phoneNumber}`,
        code: verficationCode,
        userID: result?.userID,
        reasons: description.trim() ? reasons.push(description) : reasons,
        onSuccess: () => {
          setStep((el) => el + 1);
          setLoading(false);
        },
        onError,
      });
    }
    setStep((el) => el + 1);
  };

  const onBack = () => {
    console.log("current step is back", step);
    if (step !== 1) {
      return setStep((el) => el - 1);
    }
    window.location.replace("https://imaginai.io");
  };

  const checkBoxHandler = (el: ReasonsProps) => {
    setReasonList((prevDataArray) => {
      const index = prevDataArray.findIndex((item) => item.key === el.key);
      if (index === -1) {
        return [...prevDataArray, el];
      } else {
        const newArray = [...prevDataArray];
        newArray.splice(index, 1);
        return newArray;
      }
    });
  };
  const descriptionHandler = (ev: string) => setDescriptions(ev);
  const phoneHandler = (ev: string) => setPhoneNumber(ev);
  const verficationCodeHandler = (ev: string) => setverficationCode(ev);

  const disabledNext =
    loading ||
    (step === 3 &&
      (phoneNumber.trim() === "" || phoneNumber.trim().length < 8)) ||
    (step === 4 &&
      (verficationCode.trim() === "" || verficationCode.trim().length < 6));

  const pageView = {
    1: <Step1 />,
    2: (
      <Step2
        descriptionHandler={(ev: string) => descriptionHandler(ev)}
        description={description}
        onChange={(el: ReasonsProps) => checkBoxHandler(el)}
        value={reasonList}
      />
    ),
    3: <Step3 phoneNumber={phoneNumber} onChange={phoneHandler} />,
    4: (
      <Step4
        phoneNumber={phoneNumber}
        verficationCode={verficationCode}
        onChange={verficationCodeHandler}
      />
    ),
    5: <DeleteComplete />,
  } as Record<string, ReactNode>;

  return (
    <Box
      sx={{
        ...styles.rootBoxStyle,
        bgcolor: step === 3 || step === 4 ? "#FCFDFF" : "#FFF",
      }}
    >
      <Header />
      <Box sx={styles.boxStyle}>
        {pageView[step]}

        <Collapse in={step !== 5}>
          <Stack sx={styles.stackBtnStyle}>
            <Box sx={{ width: 0.5, borderRadius: 1 }}>
              <Button
                onClick={onBack}
                variant={step === 1 ? "contained" : "outlined"}
                sx={{
                  width: 1,
                  bgcolor: step === 1 ? "#EB5757" : "transparent",
                  color: step === 1 ? "white" : "#4A5578",
                  borderColor: step === 1 ? "#EB5757" : "#4A5578",
                }}
              >
                {step === 1 ? "Non, je garde" : "Retour"}
              </Button>
            </Box>
            <Box sx={{ width: 0.5, borderRadius: 1 }}>
              <Button
                startIcon={
                  loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null
                }
                disabled={disabledNext}
                onClick={stepHandler}
                sx={{
                  bgcolor: disabledNext ? "gray" : "#ED0389",
                  color: "#fff",
                  width: 1,

                  "&:hover": {
                    background: "#ED0389",
                  },
                  "&:disabled": {
                    background: "gray",
                    opacity: 0.7,
                    color: "white",
                  },
                }}
              >
                {loading ? "" : step === 1 ? "Oui, supprimer" : "Suivant"}
              </Button>
            </Box>
          </Stack>
        </Collapse>
      </Box>
    </Box>
  );
}

const styles = {
  boxStyle: {
    pt: 5,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    overflowY: "scroll",
    overflowX: "hidden",
    height: "calc(100% - 20px)",
    maxWidth: 700,
    "&::-webkit-scrollbar": {
      display: "none",
      width: 0,
    },
  },
  rootBoxStyle: {
    px: 2,
    py: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    bgcolor: "red",
    height: "100%",
    overflow: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  stackBtnStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 2,
    mt: 2,
    mb: 4,
    width: 1,
  },
};
