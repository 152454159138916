import { Box, Stack, Typography } from "@mui/material";
import AppPhoneInput from "../../../components/AppPhoneInput";
interface Props {
  phoneNumber: string;
  onChange: (ev: any) => void;
}
export default function Step3({ onChange, phoneNumber }: Props) {
  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={{
          color: "#30374F",
          fontSize: 24,
          textAlign: "center",
          fontWeight: 500,
        }}
      >
        {"Quel est le numéro associé à votre compte?"}
      </Typography>

      <Box
        sx={{
          borderRadius: 3,
          p: "40px 32px",
          bgcolor: "#FFF",
          borderColor: "#EFF1F5",
          width: "100%",
          justifyContent: "center",
          // textAlign: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ color: "#30374F", fontSize: 20, fontWeight: 500 }}>
          {"Entrez votre numéro de téléphone"}
        </Typography>
        <Typography
          sx={{ color: "#64748B", fontSize: 14, fontWeight: 400, mb: 2 }}
        >
          {
            "Nous vous enverrons ensuite un code à 6 chiffres pour vérifier votre numéro."
          }
        </Typography>

        <AppPhoneInput
          title={"Contact"}
          titleStyle={{ color: "#344051", fontSize: 12, fontWeight: 500 }}
          value={phoneNumber}
          onChange={(ev) => onChange(ev)}
        />
      </Box>
    </Stack>
  );
}
