import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { reasons } from "../../../utils/constant";
import CheckedIcon from "../../../assets/icons/CheckedIcon";
import { ReasonsProps } from "../../../utils/data.def";
import CustomTextArea from "../../../components/CustomTextArea";

interface Props {
  value: ReasonsProps[];
  onChange: (ev: ReasonsProps) => void;
  description: string;
  descriptionHandler: (ev: string) => void;
}
export default function Step2({
  onChange,
  value,
  description,
  descriptionHandler,
}: Props) {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        mb: 5,
      }}
      spacing={2}
    >
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "#30374F",
            fontSize: 24,
            fontWeight: 500,
            textAlign: "center",
            mb: 2,
          }}
        >
          {"Pourquoi avez-vous décidé de supprimer votre compte?"}
        </Typography>
        <Typography sx={{ color: "#30374F", fontSize: 14, fontWeight: 400 }}>
          {"(Sélectionnez toutes les réponses qui s'appliquent)"}
        </Typography>
      </Stack>

      <FormGroup>
        {reasons.map((el: ReasonsProps) => (
          <FormControlLabel
            key={el.key}
            sx={{ fontSize: 16, color: "#30374F", fontWeight: 400 }}
            control={
              <Checkbox
                checkedIcon={<CheckedIcon />}
                onChange={() => onChange(el)}
                checked={value.some((ev) => ev.key === el.key)}
              />
            }
            label={el.value}
          />
        ))}
        <Collapse
          in={value.some((ev) => ev.key === "6")}
          sx={{
            marginTop: 2,
          }}
        >
          <CustomTextArea
            title={"Description"}
            value={description}
            onChange={(val: string) => descriptionHandler(val)}
          />
        </Collapse>
      </FormGroup>
    </Stack>
  );
}
