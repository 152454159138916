import { Box, Typography } from "@mui/material";
import VerificationInput from "react-verification-input";
import "./verificationCode.css";
import { formatNumber } from "../../../utils/number";

interface Props {
  verficationCode: string;
  onChange: (ev: any) => void;
  phoneNumber: string;
}
export default function Step4({ onChange, verficationCode, phoneNumber }: Props) {
  return (
    <Box sx={{
      borderRadius: 3,
      p: "40px 32px",
      bgcolor: "#FFF",
      borderColor: "#EFF1F5",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      textAlign: "center",
      flexDirection: "column"
    }}>

      <Typography
        sx={{ color: "#30374F", fontSize: 20, fontWeight: 500 }}>
        {"Code de Confirmation"}
      </Typography>
      <Typography
        sx={{ color: "#64748B", fontSize: 14, fontWeight: 400, mb: 3 }}>
        {"Entrez le code de verification recue a "}
        <span
          style={{
            fontSize: 14,
            fontWeight: 700,
            color: "#64748B"
          }}>
          {`+ ${formatNumber(Number(phoneNumber), " ")}`}
        </span>
      </Typography>
      <VerificationInput
        value={verficationCode}
        placeholder=""
        length={6}
        validChars="0-9"
        onChange={(val: any) => onChange(val)}
        classNames={{
          container: "character-container",
          character: "character",
          characterInactive: "character--inactive",
          characterSelected: "character--selected",
        }}
      />
    </Box>
  )
}
