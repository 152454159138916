import { Avatar, Box, Stack, Typography } from "@mui/material";
import { DeleteHome } from "../../../assets/images";
import { features } from "../../../utils/constant";

export default function Step1() {
  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        mb: 3,
      }}
      spacing={2}
    >
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography
          sx={{
            color: "#30374F",
            fontSize: 24,
            fontWeight: 500,
            mt: 0,
            textAlign: "center",
          }}
        >
          {"Êtes-vous certain de vouloir supprimer votre compte "}
        </Typography>
        <Typography sx={{ color: "#EB5757", fontSize: 24, fontWeight: 500 }}>
          {"définitivement "}
          <span style={{ color: "#30374F", fontSize: 24, fontWeight: 500 }}>
            {"?"}
          </span>
        </Typography>
        <Avatar src={DeleteHome} sx={{ width: 288, height: 288 }} />
      </Box>

      <ul>
        {features.map((el) => (
          <li
            key={el}
            style={{
              marginBottom: "16px",
              color: "#30374F",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            {el}
          </li>
        ))}
      </ul>
    </Stack>
  );
}
