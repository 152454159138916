import { BASE_URL } from "../utils/constant";

export const sendVerificationCode = async (
  phoneNumber: string,
  onSuccess: (args: any) => void,
  onError: (message: string) => void
) => {
  try {
    let data_to_send: any = { phoneNumber };

    const response = await fetch(`${BASE_URL}/account`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data_to_send),
    });
    if (response.ok) {
      const { data, error } = await response.json();
      if (data) {
        onSuccess(data);
      } else {
        onError(error.message);
      }
    }
  } catch (error) {
    return null;
  }
};

export const verifyCodeAsync = async ({
  code,
  phoneNumber,
  userID,
  onSuccess,
  reasons,
  onError,
}: Record<string, any>) => {
  try {
    console.log("inside verification code", reasons, phoneNumber, userID, code);
    const response = await fetch(`${BASE_URL}/account`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ code, userID, phoneNumber, reasons }),
    });
    if (response.ok) {
      const { data, error } = await response.json();
      if (data) {
        onSuccess(data);
      } else {
        onError(error.message);
      }
    }
  } catch (error) {}
};
