import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import { DelCompleteImg } from '../../../assets/images'

export default function DeleteComplete() {
    return (
        <Box sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection:"column",
            display: "flex",
            height:"80vh",
        }}>
            <Avatar src={DelCompleteImg} sx={{ width: 156, height: 156 }} variant='rounded' />
            <Typography
                sx={{
                    fontSize: 32,
                    fontWeight: 500,
                    color: "#30374F",
                    mt: 3,
                    textAlign:'center',
                }}>
                {"Votre compte a été supprimé avec succès."}
            </Typography>
        </Box>
    )
}
