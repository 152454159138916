export const formatNumber = (
    val: string | number | null,
    sep = ' '
): string => {
    if (val === null || val === undefined) {
        return '';
    }

    if (val + '' === '0') {
        return '0';
    }

    let str = val + '';

    if (!str || parseFloat(str) === 0 || str?.length === 0 || str === '') {
        return '';
    }

    const local_string = str.trim().replace(/[" "]/gi, '');

    if (local_string.split('.').length > 1) {
        const words = local_string.split('.');
        return formatNumber(words[0] + '', sep) + ',' + words[1].slice(0, 2);
    }

    if (local_string?.length <= 3) {
        return local_string;
    }
    return local_string
        ? `${formatNumber(
            local_string.slice(0, -3),
            sep
        )}${sep}${local_string.slice(local_string.length - 3)}`
        : '0';
};