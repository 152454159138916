export const features = [
  "Supprimer votre compte entraînera la perte définitive de toutes vos données associées.",
  "Vous n'aurez plus accès aux fonctionnalités de l'application une fois votre compte supprimé.",
  "Il ne sera pas possible de récupérer votre compte une fois qu'il aura été supprimé.",
  "Vous cesserez de recevoir des notifications et des communications de notre part.",
  "Les abonnements ou services payants associés à votre compte seront résiliés sans remboursement.",
  "La suppression de votre compte garantira la confidentialité et la sécurité de vos données personnelles.",
];

export const reasons = [
  { key: "1", value: "Je n'utilise plus cette application." },
  { key: "2", value: "Je préfère une autre application." },
  {
    key: "3",
    value:
      "Les fonctionnalités de l'application ne correspondent pas à mes besoins.",
  },
  { key: "4", value: "Problèmes de performance ou de fiabilité." },
  {
    key: "5",
    value:
      "J'ai des préoccupations concernant la confidentialité ou la sécurité des données",
  },
  { key: "6", value: "Autre (Veuillez spécifier)" },
];

export const BASE_URL = "https://app.imaginai.io/api/v1";
