import {
  Collapse,
  Stack,
  StackProps,
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  title?: string;
  onChange?: (args: string) => void;
  value?: string;
  titleTypographyProps?: TypographyProps;
  textAreaProps?: TextareaAutosizeProps;
  stackProps?: StackProps;
  error?: boolean;
}

export default function CustomTextArea({
  title,
  onChange,
  value,
  titleTypographyProps,
  textAreaProps,
  stackProps,
  error = false,
}: Props) {
  const [borderColor, setBorderColor] = useState("#CED2DA");

  const onFocus = () => {
    setBorderColor("#8392A2");
  };
  const onBlur = () => {
    setBorderColor("#CED2DA");
  };
  useEffect(() => {
    if (error) {
      setBorderColor("red");
    }
  }, [error]);
  return (
    <Stack {...stackProps}>
      <Typography
        sx={{
          fontSize: 14,
          mt: 2,
          mb: 0.75,
          color: "#344051",
          fontWeight: 500,
          textTransform: "capitalize",
        }}
        {...titleTypographyProps}
      >
        {title}
      </Typography>
      <TextareaAutosize
        onFocus={onFocus}
        onChange={(event) => onChange?.(event.target.value)}
        value={value}
        onBlur={onBlur}
        style={{
          color: "#292D32",
          borderColor,
          padding: "4px",
          borderRadius: "4px",
        }}
        minRows={7}
        placeholder="Veuillez spécifier"
        {...textAreaProps}
      />
      <Collapse in={error}>
        <small style={{ color: "red" }}>{title + " is_required"}</small>
      </Collapse>
    </Stack>
  );
}
